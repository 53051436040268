import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import chatheart from './chatheart.svg'
import './App.css';
import axios, { Axios, AxiosResponse } from 'axios';
import {urlFirstHello} from './services/endpoints'

function About() {
  return <h2>About</h2>;
}

function App() {

  useEffect(() => {
    console.log('Request URL:', urlFirstHello);
    axios.get(urlFirstHello)
    .then((response:AxiosResponse<any>) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.error('Error making GET request:', error);
    });
  }, [])

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src={chatheart} className="App-logo" alt="logo" />
          <p><b>And hello, you!</b></p>
          <p><b>This is coming from the react folder fr</b></p>
        </header>
        <Routes>
          <Route path="about" element={<About />} />
        </Routes>
        <Outlet />
      </div>
    </Router>
  );
}

export default App;
